<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="3"></v-col>
            <v-col cols="12" sm="2">
                <p>404</p>
            </v-col>
            <v-col cols="12" sm="4">
                <p>Oops, This Page Could Not Be Found!</p>
            </v-col>
            <v-col cols="12" sm="3"></v-col>
        </v-row>    
    </v-container>    
</template>
<script>
export default {
    name: 'PageNotFound'
    
}
</script>
<style scoped>

</style>